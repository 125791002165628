import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = { siteKey: String };

	connect() {
		let siteKey = this.siteKeyValue
		turnstile.ready(function () {
			turnstile.render('.cf-turnstile', {
				sitekey: siteKey,
				callback: function (token) {
					console.log(`Challenge Success ${token}`);
				},
			});
		});
	}
}